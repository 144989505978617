<template>
	<div>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:loading="loading"
					:items="files" 
					:headers="headers"
					:sort-by="['Posizione']" 
					:sort-desc="[false]"
					item-key="Id"
					hide-default-footer
					disable-pagination
					disable-filtering
					@click:row="editFile"
				>
					<template v-slot:[`item.Posizione`]="{ item }">
						<v-chip :color="decodificaPosizioneColore(item.Posizione)" x-small>{{ decodificaPosizione(item.Posizione) }}</v-chip>
					</template>
					<template v-slot:[`item.Caricamento`]="{ item }">
						{{ item.Caricamento | shortDate }}
					</template>					
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="text-right">
				<v-btn color="primary" @click="addFile">Aggiungi template</v-btn>
			</v-col>
		</v-row>

		<v-dialog v-model="addVisible" scrollable fullscreen transition="scale-transition" persistent @keydown.esc="close">
			<v-card :loading="loading">
				<v-toolbar dense :color="appSetup.appColor" dark max-height="50">
					<v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
					<v-toolbar-title>{{ currentFile.Id>0 ? 'Modifica' : 'Aggiungi' }} template "{{ decodificaPosizione(currentFile.Posizione) }}"</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items><v-btn text dark :disabled="!enableSave" :loading="loading" @click="save">Save</v-btn></v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pt-8">
					<div v-if="currentFile" style="height:100%; " class="d-flex flex-column">
						<div style="height:10%;">
							<v-select v-model="currentFile.Posizione" :disabled="currentFile.Id>0" label="Posizione" :items="posizioniFile" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
						</div> 
						<errors-container :errors="errors"></errors-container>
						<div style="height:90%;">
							<codemirror v-model="currentFile.FileData" :options="cmOptions" />
						</div> 
					</div>
				</v-card-text>
				<v-card-actions class="d-flex justify-space-between">
					<v-btn class="ma-6" large outlined @click="close">Annulla</v-btn>
					
					<v-btn class="ma-6" large outlined color="primary" :disabled="!enableSave" :loading="loading" @click="save">Salva</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
/**********************************************************************************************
**** ADMIN GESTIONE FILES - SPECIAL *********************************************************************
***********************************************************************************************
* usato per la gestione dei template di stampa in html
* todo: disabilita pulsante aggiunta quando tutti i template previsti sono stati caricati
*/

import ErrorsContainer from '@/components/errors-container'
// codemirror - base
import { codemirror } from 'vue-codemirror/src/index'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/lib/codemirror.css'
// codemirror - linguaggi
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/htmlmixed/htmlmixed.js'
// codemirror - temi
import 'codemirror/theme/mbo.css'
// codemirror - plugin
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/edit/closetag.js'
import 'codemirror/addon/display/autorefresh.js'
//
export default {
	name: 'AdminGestioneFilesSpecial',
	// eslint-disable-next-line vue/no-unused-components
	components: {  ErrorsContainer, codemirror },
	data: () => {
		return {
			loading: false,
			search: '',
			addVisible: false,
			posizioniFile: [
				{text:'regolamento', 				value:-10, color:'secondary'}, 
				{text:'regolamento-gold', 			value:-20, color:'secondary'}, 
				{text:'regolamento-azienda', 		value:-30, color:'primary'},
				{text:'regolamento-gold-azienda', 	value:-40, color:'primary'},
				{text:'allegato-c-proroga', 		value:-60, color:'info'},
			],
			errors: [],
			files: [],
			currentFile: {},
			expanded: [],
			headers: [
				{ text: 'Posizione', value: 'Posizione'},
				{ text: 'Data caricamento', value: 'Caricamento'}, 
			],
			cmOptions: {
				// autoRefresh: true, // potrebbe essere utile per evitare problemi di rendering
				tabSize: 4,
				styleActiveLine: true,
				lineNumbers: true,
				autoCloseTags: true,
				line: true,
				mode: 'text/html',
				// theme: 'mbo',
				fixedGutter: false, // importante per evitare problemi di rendering 
				lineNumbers: true,
			}
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		enableSave() {
			let result = !!this.currentFile.FileData
			return result 
		},
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
		this.loadFiles()
	},
	methods: {
		decodificaPosizione(posizione) {
			let dummy = this.posizioniFile.find((x) => x.value == posizione)
			return dummy ? dummy.text : '-'
		},
		decodificaPosizioneColore(posizione) {
			let dummy = this.posizioniFile.find((x) => x.value == posizione)
			return dummy ? dummy.color : 'default'
		},
		close() {
			this.addVisible = false
		},
		// carica tutti i dati
		loadFiles(){
			this.errors = []
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/file/listSpecials'}).then(result => {
				this.files = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// aggiunge un file
		async addFile() {
			this.errors = []
			var negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			this.currentFile = {Id: negativeId, FileData: '', Descrizione: '', Nome: '', Posizione: -10, VisibleDealer: false, VisibleSuperuser: false, VisibleOrganizzati: false}
			this.addVisible = true
		},
		// modifica un file
		editFile(item) {
			this.errors = []
			if(item.Id > 0) {
				// in caso di file esistente esegue una get perché per l'edit ci serve il FileDAta valorizzato
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/file/get', value: {Id: item.Id}}).then(result => {
					this.currentFile = result
					this.addVisible = true
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			} else {
				// viceversa se il file è stato appena aggiunto (id ancora negativo) il filedata è già presente
				this.currentFile = JSON.parse(JSON.stringify(item))
				this.addVisible = true
			}
		},
		// salva i dati modificati
		save() {
			this.currentFile.Nome = 'template'+this.currentFile.Posizione
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/file/setSpecial', value: this.currentFile}).then(result => {
				this.files = result
				this.addVisible = false
				this.errors = []
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		
	},
}
</script>

<style scoped lang="less">

</style> 