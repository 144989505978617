<template>
	<standard-container menu-id="admin.gestionefiles">
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab v-if="$isAuthorizedTo('admin.gestionefiles')" key="tabGestioneFilesStandard">
					Documenti
				</v-tab>
				<v-tab v-if="$isAuthorizedTo('admin.gestionefiles')" key="tabGestioneFilesSpecial">
					Template stampa
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-tabs-items v-model="currentTab">
			<!-- documenti standard  -->
			<v-tab-item v-if="$isAuthorizedTo('admin.gestionefiles')" key="tabGestioneFilesStandard" eager class="ma-1">
				<standard-files></standard-files>
			</v-tab-item>
			<!-- templalte di stampa -->
			<v-tab-item v-if="$isAuthorizedTo('admin.gestionefiles')" key="tabGestioneFilesSpecial" eager class="ma-1">
				<special-files></special-files>
			</v-tab-item>
		</v-tabs-items>
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** ADMIN GESTIONE FILES *********************************************************************
***********************************************************************************************
* 
*/
import StandardContainer from '@/components/standard-container'
import StandardFiles from '@/views/Admin/GestioneFiles/StandardFiles'
import SpecialFiles from '@/views/Admin/GestioneFiles/SpeecialFiles'
export default {
	name: 'AdminGestioneFiles',
	components: { StandardContainer, StandardFiles,SpecialFiles, },
	data: () => {
		return {
			currentTab: -1,
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},
	methods: {
		
	},
}
</script>

<style scoped lang="less">

</style> 