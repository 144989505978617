<template>
	<div>
		<v-row>
			<v-col cols="12">
				<div class="d-block d-md-flex align-baseline">
					<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" label="Ricerca libera" hide-details clear-icon="mdi-close-circle" clearable />
				</div>
			</v-col>
			<v-col cols="12">
				<v-data-table
					:loading="loading"
					:items="files" 
					:headers="headers"
					:sort-by="['Posizione', 'Nome']" 
					:sort-desc="[false]"
					item-key="Id"
					:search="search"
					show-expand
					:expanded.sync="expanded"
					:single-expand="true"
					@click:row="editFile"
				>
					<template v-slot:[`item.Posizione`]="{ item }">
						<v-chip v-if="item.Posizione == 10" color="secondary" x-small>Archivio</v-chip>
						<v-chip v-if="item.Posizione == 20" color="info" x-small>Circolari</v-chip>
						<v-chip v-if="item.Posizione == 30" color="primary" x-small>Regolamento SCard</v-chip>
					</template>
					<template v-slot:[`item.VisibleOrganizzati`]="{ item }">
						<v-chip v-if="item.VisibleOrganizzati" color="success" x-small>Y</v-chip>
					</template>
					<template v-slot:[`item.VisibleDealer`]="{ item }">
						<v-chip v-if="item.VisibleDealer" color="success" x-small>Y</v-chip>
					</template>
					<template v-slot:[`item.VisibleSuperuser`]="{ item }">
						<v-chip v-if="item.VisibleSuperuser" color="success" x-small>Y</v-chip>
					</template>
					<template v-slot:[`item.Caricamento`]="{ item }">
						{{ item.Caricamento | shortDate }}
					</template>					
					<template v-slot:[`item.Id`]="{ item }">
						<div class="d-flex justify-space-between align-baseline">
							<v-btn small icon @click.stop="viewFile(item)"><v-icon title="Apri documento" color="primary">mdi-magnify</v-icon></v-btn>
							<v-btn class="ml-4" small icon @click.stop="deleteFile(item)"><v-icon title="Elimina documento" color="warning">mdi-delete</v-icon></v-btn>
						</div>
					</template>
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length">
							<div class="expanded">
								<p class="mt-2"><strong>Descrizione:</strong></p>
								<p>{{ item.Descrizione }}</p>
							</div>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="text-right">
				<v-btn color="primary" @click="addFile">Aggiungi file</v-btn>
			</v-col>
		</v-row>

		<v-dialog v-model="addVisible" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs'" transition="scale-transition" max-width="1000" persistent @keydown.esc="close">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
					<v-toolbar-title>{{ currentFile.Id>0 ? 'Modifica' : 'Aggiungi' }} File</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<v-row v-if="loading"><v-col class="text-center"><v-progress-circular indeterminate color="primary"></v-progress-circular></v-col></v-row>					
					<v-row v-if="currentFile" class="mt-4">
						<v-col md="6">
							<v-text-field v-model="currentFile.Nome" :disabled="currentFile.Id>0" :rules="nameRules" label="Nome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
						</v-col>
						<v-col md="6">
							<v-select v-model="currentFile.Posizione" label="Posizione" :items="posizioniFile" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
						</v-col>
						<v-col md="12">
							<v-textarea v-model="currentFile.Descrizione" rows="3" label="Descrizione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
						</v-col>
						<v-col md="6">
							<v-checkbox v-model="currentFile.VisibleOrganizzati" label="Visibile Organizzati"></v-checkbox>
							<v-checkbox v-model="currentFile.VisibleDealer" label="Visibile Customer Service"></v-checkbox>
							<v-checkbox v-model="currentFile.VisibleSuperuser" label="Visibile Superuser"></v-checkbox>
						</v-col>
						<v-col md="6">
							<!-- <v-file-input v-model="currentFile.FileData" :rules="fileRules" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Documento" show-size accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" /> -->
							<file-upload-base64 v-if="currentFile.Id<=0" v-model="currentFile.FileData" />
						</v-col>
					</v-row>
					<errors-container :errors="errors"></errors-container>
					<debug-panel label="currentFile">
						{{ currentFile }}
					</debug-panel>
				</v-card-text>
				<v-card-actions class="d-flex justify-space-between">
					<v-btn class="ma-6" large outlined @click="close">Annulla</v-btn>
					<v-btn class="ma-6" large outlined color="primary" :disabled="!enableSave" :loading="loading" @click="save">Salva</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
/**********************************************************************************************
**** ADMIN GESTIONE FILES - STANDARD *********************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import ErrorsContainer from '@/components/errors-container'
import FileUploadBase64 from '@/components/file-upload-base64'
export default {
	name: 'AdminGestioneFilesStandard',
	components: { DebugPanel, ErrorsContainer,FileUploadBase64,  },
	data: () => {
		return {
			loading: false,
			search: '',
			addVisible: false,
			posizioniFile: [{text:'Archivio', value:10}, {text:'Circolari', value:20}, {text:'Regolamento SuperCard', value:30},],
			errors: [],
			files: [],
			currentFile: {},
			nameRules: [ v => !!v || 'E\' obbligatorio inserire il nome del documento',],
			expanded: [],
			headers: [
				{ text: 'Nome', value: 'Nome'},
				{ text: 'Posizione', value: 'Posizione'},
				{ text: 'Organizzati', value: 'VisibleOrganizzati', width:50}, // 
				{ text: 'C.Service', value: 'VisibleDealer', width:50}, // 
				{ text: 'Superuser', value: 'VisibleSuperuser', width:50}, // 
				{ text: 'Data caricamento', value: 'Caricamento'}, 
				{ text: '', value: 'Id', width:50}, // colonna fittizia per il save, view ed eventuale delete
			],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		darkTheme() { return this.$vuetify.theme.dark },
		currentUser() { return this.$store.getters['currentUser'] },
		enableSave() {
			let result = !!this.currentFile.Nome
			if(this.currentFile.Id<=0) {
				result = result && !!this.currentFile.FileData 
			}
			return result 
		},
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
		this.loadFiles()
	},
	methods: {
		close() {
			this.addVisible = false
		},
		// carica tutti i dati
		loadFiles(){
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/file/listAll'}).then(result => {
				this.files = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// aggiunge un file
		async addFile() {
			var negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			this.currentFile = {Id: negativeId, FileData: '', Descrizione: '', Nome: '', Posizione: 10, VisibleDealer: false, VisibleSuperuser: false, VisibleOrganizzati: false}
			this.addVisible = true
		},
		// modifica un file
		editFile(item) {
			this.currentFile = JSON.parse(JSON.stringify(item))
			this.addVisible = true
		},
		// salva i dati modificati
		save() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/file/set', value: this.currentFile}).then(result => {
				this.files = result
				this.addVisible = false
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// visualizza un singolo file
		viewFile(item) {
			this.loading = true
			this.$store.dispatch('openDocument', item.Id).then(() => {
				// nop
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		deleteFile(item) {
			this.$swal('ATTENZIONE!', 'Sei sicuro di voler eliminare il file "'+item.Nome+'" ?', 'warning', { buttons: { ok: 'Si', cancel: 'No' }}).then(result => {
				if (result === 'ok') {
					this.loading = true
					this.$store.dispatch('genericApiPost', {apiUrl: '/file/delete', value: {Id: item.Id}}).then(result => {
						this.files = result
					}).catch(error => {
						this.errors.push({text: error, pre: true})
					}).finally(() => {
						this.loading = false
					})
				}
			})
		},
	},
}
</script>

<style scoped lang="less">

</style> 