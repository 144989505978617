<template>
	<div>
		<v-file-input v-model="fileData" :loading="loading" :messages="progress" :rules="fileRules" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="label" show-size :accept="accept" @change="fileChange" />
		<debug-panel label="" class="mt-6">
			{{ }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** ---- ****************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
//
export default {
	name: 'FileUploadBase64',
	components: { DebugPanel },
	props: {
		// v-model
		value: {
			type: String,
			required: false,
			default: () => {return ''}
		},
		accept: {
			type:String,
			required: false,
			default:()=>{return '.pdf,.doc,.docx,.xls,.xlsx'}
		},
		maxSize: {
			type: Number,
			required: false,
			default: () => {return 4000000}
		},
		maxSizeMessage: {
			type: String,
			required: false,
			default: () => {return 'Il documento allegato non deve pesare più di 4 MB!'}
		},
		label: {
			type:String,
			required: false,
			default: () => {return 'Documento'}
		},
	},
	data: () => {
		return {
			loading: false,
			progress: '',
			fileData: {}
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		fileRules() {
			return [
				// value => !!value || !!value.size || 'E\' obbligatorio allegare un documento',
				value => !value || value.size < this.maxSize || this.maxSizeMessage,
			]
		}
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
		this.fileData = {}
	},
	methods: {
		fileChange(file) {
			if (file && file.size > 0 && file.size < this.maxSize) {
				// console.warn('fileChange', file)
				this.createImage(file)
			}
		},
		createImage(file) {
			// console.warn('createImage', file)
			let reader = new FileReader()
			var _this = this
			reader.onload = (e) => {
				_this.$emit('input', e.target.result)
			}
			reader.onloadstart = (e) => {
				_this.loading = true
			}
			reader.onloadend = (e) => {
				_this.loading = false
			}
			reader.onprogress = (e) => {
				if (e.lengthComputable) {
					_this.progress = parseInt(((e.loaded / e.total) * 100), 10) + '%' 
				}
			}
			// carica file
			reader.readAsDataURL(file)
		},
		removeImage: function (e) {
			this.$emit('input', '')
		}
	},
}
</script>

<style scoped lang="less">

</style>